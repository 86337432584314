.topBar {
  position: sticky;
  z-index: 9999;
  top: 0;
}

.accountStatus {
  color: var(--labelColor);
}

.company {
  font-size: 12px;
  color: var(--labelColor);
}

.accountName {
  color: var(--primaryGray);
}

.spinnerModifications.spinnerModifications {
  transform: scale(0.25);
  height: 12px;
  width: 12px;
}
