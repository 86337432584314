.userRoot {
  position: relative;
}

/* Title */

.title {
  font-size: 24px;
  font-weight: var(--fontWeightMedium);
  color: var(--textColor);
  float: left;
}

.usersTypeSubTitle {
  font-size: 12px;
  font-weight: var(--fontWeightMedium);
  color: var(--textColor);
  float: left;
  line-height: 11px;
  margin-left: -12px;
}

.zeroResultsSubtitle {
  display: block;
  float: none;
  margin-left: 0;
  margin-bottom: 10px;
}

.zeroResultsMsg {
  color: var(--labelColor);
  font-size: 16px;
  margin-top: 5px;
}

.linkWrapper {
  text-align: right;
}

.linkWrapper span {
  display: inline-block;
  width: auto;
  height: 44px;
  padding: 12px 36px;
  font-size: 18px;
  border: solid 2px var(--primaryBlue);
  border-radius: 24px;
  cursor: pointer;
}

.btnText {
  width: 143px;
  height: 18px;
  font-family: var(--defaultFontFamily);
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: var(--primaryBlue);
}

.marginRight {
  margin-right: 15px;
}

.marginLeft {
  margin-left: 15px;
}

.userSectionGap {
  height: 35px;
  width: 100%;
  clear: both;
}

.reactivate {
  width: 290px;
  visibility: hidden;
}

.edit {
  width: 90px;
  visibility: hidden;
}

.edit,
.reactivate {
  color: var(--labelColor);
  font-size: 16px;
  text-align: right;
}

.sectionInfo {
  margin-top: 8px;
}

.userListWrapper {
  position: relative;
  margin-top: 5px;
}

.userItems {
  scrollbar-width: thin;
  position: relative;
  padding-top: 30px;
  padding-bottom: 30px;
  height: 100%;
}

.userItems::-webkit-scrollbar {
  height: 7px;
  width: 7px;
}

.userItems::-webkit-scrollbar-track {
  background: var(--backgroundGray);
}

.userItems::-webkit-scrollbar-thumb {
  background-color: var(--scrollbarThumb);
}

.userItems::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbarThumbHover);
}

.userStatusFilterWrapper {
  display: flex;
}

.override.userColumnSwitcher {
  margin-left: auto;
}

.override.userColumnSwitcherButton {
  height: 32px;
}

.filterIconSize {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.override.override.userHeading {
  white-space: normal;
  user-select: none;
}

.override.override.userTableCell {
  height: 48px;
}

.truncatedCellText {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lonelyText {
  margin-top: 80px;
  font-size: 18px;
  color: var(--textColor);
  text-align: center;
  font-weight: var(--fontWeightMedium);
}

.lonelyTextDesc {
  margin-top: 10px;
  font-size: 12px;
  color: var(--labelColor);
  text-align: center;
}

.lonelyBtnWrapper {
  text-align: center;
}

.lonelyBtnWrapper span {
  margin-top: 20px;
  display: inline-block;
  width: auto;
  height: 44px;
  padding: 12px 36px;
  font-size: 18px;
  color: var(--primaryBlue);
  font-weight: 500;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  border: solid 2px var(--primaryBlue);
  border-radius: 24px;
  cursor: pointer;
}

.tabHidden {
  display: none;
}

.noUsers {
  color: var(--labelColor);
  margin-bottom: 10px;
}

.row {
  display: flex;
  flex-direction: row;
  margin: 0 -20px;
}

.col {
  flex: 1;
  padding-left: 20px;
  padding-right: 20px;
}

.loaderRoot {
  position: relative;
  background-color: transparent;
  height: 200px;
}

.userErrorMode {
  pointer-events: none;
  filter: blur(3px);
  opacity: 0.5;
}

.logoCircle {
  width: 34px;
  height: 34px;
  background: var(--avatarBackground);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.override.idpUserLockedIcon {
  width: 16px;
  height: 16px;
}

.override.reactivatedEmailIcon {
  width: 18px;
  height: 18px;
}

.emailReactivatedTooltip {
  white-space: pre-line;
}

.emailReactivatedTooltip :global(.plume-ui-tooltip-arrow) {
  z-index: 1000;
}

.emailReactivatedTooltip :global(.plume-ui-tooltip-inner) {
  transform: translateX(40%);
}

.logoText {
  font-size: 16px;
  color: var(--white);
  line-height: 36px;
}

.tableDataRow {
  padding: 5px;
}

.pagination {
  margin-top: 10px;
  float: right;
}

.chipStyle {
  max-width: 70px !important;
}

.iconSize {
  width: 24px !important;
  height: 24px !important;
}

.addUserButton {
  float: right;
}

.ndaBoxStyle {
  margin-top: 30px;
}

.override.tooltipVisible {
  display: initial;
}

.showTooltipIfDisplayWidth {
  display: none;
}

.override.percentageRingColor svg circle:nth-child(1) {
  stroke: rgba(255, 255, 255, 0.13);
}

.override.percentageRingColor svg circle:nth-child(2) {
  stroke: var(--primaryBlue);
}

/* @media only screen and (max-width: 1400px) {
  .override.tableSize {
    width: 100%;
    table-layout: fixed;
  }

  .headingSizes th:nth-child(1) {
    width: 60px;
  }

  .headingSizes th:nth-child(2) {
    width: 180px;
  }

  .headingSizes th:nth-child(7) {
    width: 120px;
  }

  .headingSizes th:nth-child(8) {
    width: 60px;
  }

  .showTooltipIfDisplayWidth {
    display: initial;
  }
} */

@media only screen and (max-width: 1024px) {
  .btnText {
    width: 143px;
    height: 18px;
    font-family: var(--defaultFontFamily);
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: var(--primaryBlue);
  }

  .marginRight {
    margin-right: 0;
  }

  .marginLeft {
    margin-left: 0;
  }

  .userListWrapper {
    margin-top: 20px;
  }

  .userSectionGap {
    height: 20px;
    width: 100%;
    clear: both;
  }

  .periodWrapper {
    width: 190px;
  }

  .linkWrapper {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
  }

  .row {
    flex-direction: column;
  }

  .col {
    width: 100%;
  }

  .override.tableSize {
    table-layout: auto;
    width: 100%;
  }

  .userItems {
    overflow-x: auto;
    scrollbar-width: thin;
    position: relative;
    padding-top: 20px;
    padding-bottom: 30px;
    min-height: 450px;
    height: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .userStatusFilterWrapper {
    flex-direction: column;
  }

  .override.userColumnSwitcher {
    margin: 10px auto 0 0;
  }
}
