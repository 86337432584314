.modalRoot {
  :global(.Modal__body) {
    padding: 24px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.formField {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.label {
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

.dropdown {
  width: 100%;

  :global(.DropdownHeader) {
    width: 100%;
    justify-content: space-between;
  }
}
