/* @import "style/colors.css"; */
@import 'style/colors-dark.css';
@import 'style/typography.css';
@import 'style/sentry-dialog.css';
@import 'sanitize.css';

/* @import-normalize; */

@font-face {
  font-family: wigrumweb;
  font-weight: 300;
  src: url('../assets/fonts/wigrumweb-regular.woff') format('woff');
  ascent-override: 85%;
}

@font-face {
  font-family: wigrumweb;
  font-weight: 500;
  src: url('../assets/fonts/wigrumweb-medium.woff') format('woff');
  ascent-override: 85%;
}

@font-face {
  font-family: wigrumweb;
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/wigrumweb-bold.woff') format('woff');
  ascent-override: 85%;
}

@font-face {
  font-family: '-OC Rey';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/-OCRey-Regular.woff') format('woff');
}

html {
  font: 100%/1.2 wigrumweb, 'Helvetica Neue', 'Segoe UI', 'Helvetica', 'Arial', sans-serif;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html,
body {
  height: 100%;
  overscroll-behavior: none;
}

body {
  margin: 0;
  color: var(--textColor);
  font-family: var(--defaultFontFamily);
  font-weight: var(--fontWeightRegular);
  word-wrap: break-word;
  font-kerning: normal;
  font-feature-settings: 'kern', 'liga', 'clig', 'calt', 'kern';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
dd,
dl,
ol,
ul {
  padding: 0;
  margin: 0 0 24px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: inherit;
  font-family: var(--defaultFontFamily);
  font-weight: var(--fontWeightMedium);
  text-rendering: optimizeLegibility;
  line-height: var(--headingLineHeight);
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 27px;
}

h3 {
  font-size: 23px;
}

h4 {
  margin: 0 0 20px;
  font-weight: 700;
  font-size: 16px;
}

h5 {
  font-size: 13px;
}

h6 {
  font-size: 12px;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 9999px var(--backgroundGray);
  -webkit-text-fill-color: var(--white);
}

a {
  cursor: pointer;
  color: var(--primaryBlue);
  text-decoration: none;
}

/* can also change it by
.ReactModal__Overlay {
  background: rgba(0,0,0,.4)!important;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
(prop)overlayClassName= */

:global(.ReactModal__Overlay) {
  z-index: 10000 !important;
}

:global(#root),
:global(#root > div:first-child) {
  min-height: 100%;
}

@supports (zoom: 2) {
  @media only screen and (min-width: 2560px) {
    html {
      --zoomFactor: 1.3;
      --vpUnitsFactor: 1.3;
    }
  }

  @media only screen and (min-width: 2880px) {
    html {
      --zoomFactor: 1.5;
      --vpUnitsFactor: 1.5;
    }
  }

  @media only screen and (min-width: 3840px) {
    html {
      --zoomFactor: 2;
      --vpUnitsFactor: 2;
    }
  }

  body {
    zoom: var(--zoomFactor, 1);
  }
}
