.section {
  margin-bottom: 80px;
}

.header {
  margin-bottom: 10px;
}

.knowledgeTiles {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px 80px;
}

.knowledgeTiles > * {
  max-width: 380px;
}

.headerTitle {
  font-size: 24px;
  font-weight: var(--fontWeightMedium);
  color: var(--textColor);
  width: 66%;
}

.loaderRoot {
  position: relative;
  background-color: transparent;
  height: 200px;
}

@media only screen and (max-width: 1024px) {
  .knowledgeTiles {
    display: flex;
    justify-content: center;
  }
}
