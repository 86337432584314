.ConfigurationCardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  z-index: 1;
  box-sizing: border-box;
  background-color: var(--backgroundGray);
  border-bottom: 1px solid var(--backgroundHeader);
  color: var(--labelColor);
}

.ConfigurationCardActionsContainer {
  display: flex;
  align-items: center;
}

.ConfigurationCardAction {
  margin-left: 10px;
}

.ConfigurationCard {
  position: relative;
}

.ConfigurationCardContent {
  padding: 20px;
  line-height: 140%;
}

.ConfigurationCardContentLoading {
  padding: 0;
}

.CardLoadingHeight {
  height: 61px;
}

.showNewlines {
  margin-right: auto;
  white-space: pre-line;
}

.cardButtonWidth {
  padding-left: 0;
  padding-right: 0;
}

.personPlumeIcon {
  height: 24px;
  width: 24px;
  margin-right: 10px;
}

.personPlumeIcon path {
  fill: var(--labelColor);
}

.pendingContent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  pointer-events: none;
}

@media only screen and (max-width: 500px) {
  .ConfigurationCardHeader {
    flex-direction: column;
    height: unset;
    align-items: flex-start;
  }

  .ConfigurationCardAction {
    margin-left: 0;
  }

  .CardLoadingHeight {
    height: unset;
  }
}
