.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  overflow: hidden;
}

.filters {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: hidden;
}

.filter {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.filter label {
  color: var(--labelColor);
}

.tableContainer {
  max-width: 100%;
  overflow-x: auto;
}

.dangerText {
  color: var(--error);
}

.pill {
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  gap: 4px;
  padding: 7px 8px 4px 8px;
  border-radius: 999px;
  background-color: var(--backgroundGray);
  color: var(--labelColor);
}

.pillSuccess {
  color: var(--good500);
  background-color: color-mix(in srgb, var(--good500), transparent 70%);
}

.pillDanger {
  color: var(--error);
  background-color: color-mix(in srgb, var(--error), transparent 70%);
}

.actionButton :global(.Button) {
  padding: 0;
  height: 24px;
}

.actionButtonDanger :global(.Button) {
  color: var(--error);
}
