.headerTitle {
  font-size: 24px;
  font-weight: var(--fontWeightMedium);
  color: var(--textColor);
}

.content {
  display: flex;
  margin: 5px 0 40px;
}

.col {
  width: 380px;
  margin: 0 10px;
  display: none;
}

.override.longCardRoot {
  margin: 20px 10px 10px 0;
  width: unset;
}

.override.longCardContent {
  padding: 0;
}

.override.longCardHeader {
  padding: 24px;
  border-bottom: unset;
}

.cardsContainer {
  display: grid;
  /* stylelint-disable-next-line */
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 24px;
  width: 100%;
  margin: 10px 10px 10px 0;
}

.cardsContainer > :global(.Card) {
  position: relative;
}

.cardButtonWidth {
  padding-left: 0px;
  padding-right: 0px;
}

.container {
  position: relative;
  margin: 20px 0;
  padding: 20px;
  background: var(--backgroundGraySection);
  border-radius: 16px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
}

.container.speedTestMinHeight {
  min-height: 108px;
}

.container.ssoConfigMinHeight {
  min-height: 108px;
}

.title {
  margin-bottom: 8px;
  padding-right: 70px;
  font-size: 1rem;
  font-weight: var(--fontWeightMedium);
  color: var(--textColor);
}

.partnerIdTitle {
  padding-right: 0;
}

.showNewlines {
  white-space: pre-line;
}

.staticCardDescription {
  font-size: 0.875rem;
  line-height: 140%;
  color: var(--labelColor);
  margin-top: 4px;
}

.text {
  font-size: 1rem;
  line-height: 140%;
}

.text.strongInNewline strong {
  display: block;
  color: var(--labelColor);
}

.marigold {
  color: var(--marigold);
}

.partnerInfo {
  display: inline-block;
}

.partnerId {
  float: right;
}

.editWrapper {
  position: absolute;
  top: 20px;
  right: 20px;
}

.edit {
  color: var(--textColor);
  cursor: pointer;
}

.actionButtonWrapper {
  position: absolute;
  top: 20px;
  right: 20px;
}

.actionButton {
  color: var(--textColor);
  cursor: pointer;
}

.sendEmailWrapper {
  text-align: right;
}

.sendEmail {
  color: var(--primaryBlue);
  cursor: pointer;
}

.releaseNotesLink {
  padding-top: 10px;
}

.sftpSharingText {
  padding-top: 10px;
  white-space: pre-line;
}

.customization,
.mobileSSO {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 780px;
  margin: 10px;
  padding: 15px;
  background: var(--backgroundGraySection);
  border-radius: 16px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
}

.mobileSSO {
  position: relative;
  margin-top: 20px;
  margin-bottom: 0;
  min-height: 84px;
}

.customization button,
.mobileSSO button {
  width: 140px;
  margin: 0;
}

.customizationDesc,
.mobileSSODesc {
  position: relative;
}

.leftSpace {
  margin-left: 105px;
}

.appScreenIcon {
  position: absolute;
  bottom: -15px;
  width: 73px;
  height: 103px;
  border: 0;
}

.appCustomizationBtnWrapper {
  position: relative;
}

.logoUploadModal {
  padding: 10px 0;
}

.logoUploadBtnWrapper {
  position: relative;
}

.setupNotification {
  position: absolute;
  top: 0;
  right: -5px;
  padding: 2px 0 0 5px;
  width: 18px;
  height: 18px;
  color: white;
  font-size: 12px;
  font-weight: var(--fontWeightMedium);
  background: #fa1478;
  border: 1px solid #fa1478;
  border-radius: 50%;
}

.deleteAccount button {
  margin-top: 20px;
  margin-bottom: 0;
  padding: 0 30px;
  background-color: #cc0a0a;
}

.deleteMobileAppSSO button {
  margin-right: 8px;
  border-radius: 25px;
  display: inline-block;
  margin-bottom: 0;
  background: none;
  border: 2px solid var(--error);
  color: var(--error);
}

.loaderRoot {
  position: relative;
  background-color: transparent;
  max-width: 780px;
  height: 200px;
}

.tooltipText {
  display: block;
  white-space: pre;
}

.btnDisabled {
  background-color: var(--borderColor);
}

.mobileSSOConfig {
  position: relative;
  margin-bottom: 20px;
  padding: 20px;
  background: var(--backgroundGraySection);
  border-radius: 16px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
  max-width: 780px;
}

.sqlCreate {
  color: var(--primaryBlue);
  cursor: pointer;
}

.sqlPercentage {
  margin: 10px auto;
  width: 44px;
}

@media only screen and (max-width: 1660px) {
  .cardsContainer {
    /* stylelint-disable-next-line */
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media only screen and (max-width: 1260px) {
  .cardsContainer {
    /* stylelint-disable-next-line */
    grid-template-columns: auto;
  }
}

@media only screen and (max-width: 1024px) {
  .content {
    margin: 10px 0;
    display: flex;
    flex-direction: column;
  }

  .col {
    width: auto;
  }

  .partnerId {
    float: none;
  }

  .partnerIdContainer {
    padding: 30px 20px;
  }

  .partnerInfo {
    display: block;
  }

  .products {
    margin-top: 10px;
  }

  .customization,
  .mobileSSO {
    flex-direction: column;
  }

  .appScreenIcon {
    bottom: -26px;
  }

  .customizationDesc,
  .mobileSSODesc {
    margin-bottom: 10px;
  }

  .appCustomizationBtnWrapper {
    padding-left: 40px;
  }

  .cardsContainer {
    width: unset;
    margin: 10px;
  }

  .override.longCardRoot {
    margin: 20px 10px 10px 10px;
  }
}

@media only screen and (max-width: 500px) {
  .override.longCardHeader {
    height: unset;
    padding: 10px;
    flex-direction: column;
    align-items: unset;
  }

  .override.alignLongCardButton {
    margin-left: 0;
    margin-right: auto;
  }
}
